<template>
  <v-dialog v-model="dialog" persistent :overlay="false" max-width="800px" transition="dialog-transition">

    <modifier-objectif-wagons-popup ref="popupObjectifs" @objectifsValides="objectifsValidesEventHandler"></modifier-objectif-wagons-popup>

    <v-card>
      <v-card-title primary-title>
        Modification des trajets
      </v-card-title>
      
      <v-card-text style="max-height: 700px; overflow: auto ;">
        <h-group v-for="(trajet, index) in trajets" :key="index" style="border: 1px solid #E0E0E0" class="my-0 pa-1">
          <v-checkbox v-model="trajet.selectionne" hide-details></v-checkbox>
          <div style="width: 200px"><v-text-field hide-details label="Trajet" v-model="trajet.nom"></v-text-field></div>
          <div class="ml-2">Objectif :</div>
          <v-btn color="primary" text  @click="boutonModifierObjectifs(trajet)">{{ getObjectif(trajet) }}</v-btn>
          <v-btn color="error" x-small class="ml-4" @click="boutonSupprimerTrajet(trajet)">supprimer</v-btn>
        </h-group>
      </v-card-text>

      <v-card-actions>
        <v-btn small color="primary" @click="boutonAjouterTrajet">ajouter</v-btn>
        <v-btn small color="primary" @click="boutonToutSelectionner">Tout sélectionner</v-btn>
        <v-btn small color="primary" @click="boutonToutDeselectionner">Tout désélectionner</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="boutonOk">ok</v-btn>
        <v-btn text @click="dialog = false">annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModifierObjectifWagonsPopup from './ModifierObjectifWagonsPopup'
import moment from 'moment'

export default {

    components: { ModifierObjectifWagonsPopup },

    data() {
      return {
        dialog: false,
        trajets: []
      }
    },

    methods: {
      open(trajets) {
        this.trajets = JSON.parse(JSON.stringify(trajets))
        this.dialog = true
      },

      boutonSupprimerTrajet(trajet) {
        var ok = confirm("Supprimer le trajet " + trajet.nom + " ?")
        if (ok) {
          const index = this.trajets.indexOf(trajet)
          if (index >= 0) {
            this.trajets.splice(index, 1)
          }
        }
      },

      boutonAjouterTrajet() {
        var date = moment().format('YYYY-MM-DD')
        this.trajets.push({nom: '', objectifsWagons: [{date: date, objectif:0}]})
      },

      getObjectif(trajet) {
        var idx = trajet.objectifsWagons.length - 1
        if (idx < 0) {
          return 0
        }
        if (idx == 0) {
          return trajet.objectifsWagons[0].objectif
        }
        const now = new Date()
        while (idx >= 0) {
          const d = new Date(trajet.objectifsWagons[idx].date)
          if (now > d) {
            return trajet.objectifsWagons[idx].objectif
          }
          idx--
        }
        return 0
      },

      boutonModifierObjectifs(trajet) {
        this.memoTrajet = trajet
        this.$refs.popupObjectifs.open(trajet)
      },

      objectifsValidesEventHandler(trajet) {
        this.memoTrajet.objectifsWagons = trajet.objectifsWagons

      },

      boutonToutSelectionner() {
        this.trajets.forEach(t => t.selectionne = true);
      },

      boutonToutDeselectionner() {
        this.trajets.forEach(t => t.selectionne = false);
      },

      boutonOk() {
        this.trajets.sort(function(a, b) { return a.nom < b.nom ? -1 : (a.nom == b.nom ? 0 : 1)})
        this.$emit("trajetsValides", this.trajets)
        this.dialog = false
      }
    }
}
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
</style>