<template>
  <v-dialog v-model="dialog" persistent :overlay="false" max-width="800px" transition="dialog-transition">
    <v-card>
      <v-card-title primary-title>
        Modification des objectifs {{ trajet.nom }}
      </v-card-title>
      
      <v-card-text style="max-height: 600px; overflow: auto ;">
        <h-group v-for="(objectif, index) in objectifsWagons" :key="index" style="border: 1px solid #E0E0E0" class="my-1 pa-1">
          <div style="width: 300px"><v-text-field hide-details type="date" label="A partir de" v-model="objectif.date"></v-text-field></div>
          <div style="width: 100px" class="ml-4"><v-text-field hide-details label="Objectif" type="number" v-model.number="objectif.objectif"></v-text-field></div>
          <v-btn color="error" x-small class="ml-4" @click="boutonSupprimerObjectif(objectif)">supprimer</v-btn>
        </h-group>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="boutonAjouterObjectif">ajouter</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="boutonOk">ok</v-btn>
        <v-btn text @click="dialog = false">annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {

    data() {
      return {
        dialog: false,
        trajet: {},
        objectifsWagons: []
      }
    },

    methods: {
      open(trajet) {
        this.trajet = JSON.parse(JSON.stringify(trajet))
        this.objectifsWagons = this.trajet.objectifsWagons
        this.dialog = true
      },

      boutonSupprimerObjectif(objectif) {
        var ok = confirm("Supprimer cet objectif ?")
        if (ok) {
          const index = this.objectifsWagons.indexOf(objectif)
          if (index >= 0) {
            this.objectifsWagons.splice(index, 1)
          }
        }
      },

      boutonAjouterObjectif() {
        var objectif = 0
        if (this.objectifsWagons.length > 0) {
          objectif = this.objectifsWagons[this.objectifsWagons.length - 1].objectif
        }
        const date = moment().format("YYYY-MM-DD")
        this.objectifsWagons.push({date: date, objectif: objectif})
      },

      boutonOk() {
        this.$emit("objectifsValides", this.trajet)
        this.dialog = false
      }
    }
}
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
</style>