<template>
  <v-container grid-list-xs>

    <modifier-trajets-popup ref="popupModifierTrajets" @trajetsValides="trajetsValidesEventHandler"></modifier-trajets-popup>

    <v-card>
      <v-card-title primary-title>
        KPI - Indicateurs clés de performance -&nbsp;<span class="indice" style="color: #404040; text-decoration: none;">issus des algorithmes flybox de <a href="https://www.linaware.eu" target="_blank" style="color: #404040;">Linaware</a></span>
      </v-card-title>
      <v-card-text>

        <!-- Boutons de sélection -->
        <h-group class="mb-3">
          <!-- Période sur mésure -->
          <div style="width: 150px"><v-text-field hide-details type="date" label="Du" v-model="s1dateDebut"></v-text-field></div>
          <div style="width: 150px" class="ml-2"><v-text-field hide-details type="date" label="au" v-model="s1dateFin" @change="saisieDateFinChangeEvent"></v-text-field></div>

          <!-- Période par mois -->
          <v-group class="ml-5">
            <v-btn color="success" x-small @click="moisEnCours" :loading="loading">Mois en cours</v-btn>
            <h-group class="mt-1" style="justify-content: space-around;">
              <v-btn color="success" x-small @click="moisMoinsUn" :loading="loading">M - 1</v-btn>
              <v-btn color="success" class="ml-1" x-small @click="moisPlusUn" :loading="loading">M + 1</v-btn>
            </h-group>
          </v-group>

          <!-- Période par année -->
          <v-group class="ml-5">
            <v-btn color="success" hide-details x-small @click="anneeEnCours" :loading="loading">Année en cours</v-btn>
            <h-group class="mt-1" style="justify-content: space-around;">
              <v-btn color="success"  x-small @click="anneeMoinsUn" :loading="loading">A - 1</v-btn>
              <v-btn color="success" class="ml-1"  x-small @click="anneePlusUn" :loading="loading">A + 1</v-btn>
            </h-group>
          </v-group>

          <!-- Regrouper par trajet ou par mois -->
           <v-radio-group v-model="regroupement" class="ml-5">
            <v-radio label="Par trajet" :value="0" @click="agregerResultatParGroupe(trains)"></v-radio>
            <v-radio label="Par mois" :value="1" @click="agregerResultatParGroupe(trains)"></v-radio>
           </v-radio-group>


          <v-btn color="success" class="ml-5" @click="boutonOuvrirPopupModifierTrajets" :loading="loading">Sélection des trajets</v-btn>
        </h-group>

        <!-- Tableau des trajets sélectionnés et courbes associées -->
        <h-group style="align-items: top; overflow: auto; max-height: 70dvh;">

          <!-- tableau de données des trajets sélectionnés -->
          <div>
            <div style="max-width: 800px; font-size: 12px;" v-if="regroupement == REGROUPEMENT_MOIS"> Trajets : {{ trajetsSelectionnesToString(trajetsSelectionnes) }}</div>
            <table>
              <thead>
                <tr>
                  <th class="text-left">{{ regroupement == REGROUPEMENT_TRAJET ? 'Trajet' : 'Mois' }}</th>
                  <th class="text-center">Trains<br/>prévisionnels</th>
                  <th class="text-center">Trains<br/>couverts</th>
                  <th class="text-center">Trains<br/>en cours</th>
                  <th class="text-center">Trains<br/>fermés</th>
                  <th class="text-center">Trains<br/>annulés</th>
                  <th class="text-center">Trains<br/>nb total</th>
                  <th class="text-center">% de trains<br/>annulés</th>
                  <th class="text-center">Nb wag.moy</th>
                  <th class="text-center">Objectif</th>
                  <th class="text-center">Ratio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(groupe, indexGroupe) in groupes" :key="indexGroupe">
                  <td>{{ groupe.nom }}</td>
                  <td>{{ groupe.compteurs[0] }}</td>
                  <td class="couvert">{{ groupe.compteurs[1] }}</td>
                  <td class="ouvert">{{ groupe.compteurs[2] }}</td>
                  <td class="fermeok">{{ groupe.compteurs[3] }}</td>
                  <td class="annule">{{ groupe.compteurs[4] }}</td>
                  <td >{{ groupe.nbTrainsReels }}</td>
                  <td >{{ (groupe.compteurs[4] + groupe.compteurs[3] > 0) ? (Math.round(100 * groupe.compteurs[4] / (groupe.compteurs[4] + groupe.compteurs[3]))) : '-' }} %</td>
                  <td>{{ groupe.nbWagonsMoyens != undefined ? groupe.nbWagonsMoyens.toFixed(1) : '-' }}</td>
                  <td>{{ regroupement == REGROUPEMENT_TRAJET ? getObjectif(groupe).toFixed(1) : '-' }}</td>
                  <td>{{ groupe.ratio != undefined ? groupe.ratio.toFixed(1) : '-' }} %</td>
                </tr>
                <tr>
                  <td>TOTAUX</td>
                  <td>{{ totaux.compteurs[0] }}</td>
                  <td class="couvert">{{ totaux.compteurs[1] }}</td>
                  <td class="ouvert">{{ totaux.compteurs[2] }}</td>
                  <td class="fermeok">{{ totaux.compteurs[3] }}</td>
                  <td class="annule">{{ totaux.compteurs[4] }}</td>
                  <td >{{ totaux.nbTrainsReels }}</td>
                  <td >{{ (totaux.compteurs[4] + totaux.compteurs[3] > 0) ? (Math.round(100 * totaux.compteurs[4] / (totaux.compteurs[4] + totaux.compteurs[3]))) : '-' }} %</td>
                  <td>{{ totaux.nbWagonsMoyens != undefined ? totaux.nbWagonsMoyens.toFixed(1) : '-' }}</td>
                  <td></td>
                  <td>{{ ((totaux.ratio != undefined) && (totaux.ratio != 0))  ? totaux.ratio.toFixed(1) : '-' }} %</td>
                </tr>
              </tbody>
            </table>

          </div>

          <!-- Courbes -->
          <v-group class="ml-3">
            <template>
              <v-tabs v-model="tab">
                <v-tab>En synthèse</v-tab>
                <v-tab>Annulations</v-tab>
                <v-tab>Liste des trajets</v-tab>
              </v-tabs>
            </template>

            <v-tabs-items v-model="tab">
              <!-- En synthèse -->
              <v-tab-item :eager="true">
                  <!-- courbe -->
                  <div>
                    <canvas ref="chart1"  width="600" height="400"></canvas>
                  </div>
              </v-tab-item>

              <!-- Annulations -->
              <v-tab-item :eager="true">
                <v-switch label="Regrouper par catégorie" v-model="annulationsParCategorie" @change="boutonRegrouperParCategorieClick"></v-switch>
                <div style="width: 800px; height: 600px">
                  <canvas ref="chart2" ></canvas>          
                </div>
              </v-tab-item>

              <!-- Liste des trajet -->
              <v-tab-item>
                <v-simple-table dense fixed-header height="550px" class="pa-4" style="width: 700px;">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" >
                          Id 
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('frId', true)">mdi-arrow-up</v-icon>
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('frId', false)">mdi-arrow-down</v-icon>
                        </th>
                        <th class="text-left">
                          Flux
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('frDepartLieu', true)">mdi-arrow-up</v-icon>
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('frDepartLieu', false)">mdi-arrow-down</v-icon>
                        </th>
                        <th class="text-left">
                          Statut
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('frStatut', true)">mdi-arrow-up</v-icon>
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('frStatut', false)">mdi-arrow-down</v-icon>
                        </th>
                        <th class="text-left">
                          Wagons
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('count(wgId)', true)">mdi-arrow-up</v-icon>
                          <v-icon x-small color="white" class="ml-1" @click="trierTrajets('count(wgId)', false)">mdi-arrow-down</v-icon>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr v-for="flux in listeFlux" :key="flux.frId">
                        <td>{{ flux.frId }}</td>
                        <td>{{ flux.frDepartLieu }} - {{ flux.frArriveeLieu }}</td>
                        <td>
                          <h-group>
                          <div :class="getStatutColor(flux.frStatut)" style="width: 20px; height: 20px;"></div>
                          <div class="ml-2">{{ getStatutText(flux.frStatut) }}</div>
                        </h-group>
                        </td>
                        <td>{{ flux["count(wgId)"] }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-group>
        </h-group>

      </v-card-text>
    </v-card>
    
  </v-container>
</template>

<script>
import Chart from 'chart.js';
import ModifierTrajetsPopup from './ModifierTrajetsPopup.vue'
import moment from 'moment';

export default {
  components: { ModifierTrajetsPopup  },

  data() {
    return {
      tab: null,
      TAB_SYNTHESE:0,
      TAB_ANNULATION:1,
      TAB_LISTE:2,
      listeFlux: [],
      loading: false,
      trajets:[],
      trajetsSelectionnes:[],
      annulations: {
        labels: [],
        nb: [],
        pct: []
      },
      chartData: {
        labels: [ 'January', 'February', 'March' ],
        datasets: [ { data: [40, 20, 12] } ]
      },
      chartOptions: {
        responsive: true
      },
      dateDebut: new Date(),
      dateFin: new Date(),
      s1dateDebut: "",
      s1dateFin: "",
      totaux : {
        nbTrainsReels: 0,
        compteurs: [0, 0, 0, 0, 0],
        nbWagons: 0,
        nbWagonsMoyens: 0,
        nbWagonsObjectifs: 0,
        nb: 0,
        ratio: 0
      },      
      annulationsParCategorie: true,
      groupes: [],
      trains: [],
      regroupement: 0,
      REGROUPEMENT_TRAJET: 0,
      REGROUPEMENT_MOIS: 1
    }
  },

  created() {
    this.lireTrajets()
  },

  methods: {
    lireTrajets() {
      const url = "/api/frFeuilleRoutes/trajetsKpi?access_token=" + this.$user.id
      this.$axios.get(url)
        .then(function(response) {
          const trajets = response.data
          for (var idxTrajet = 0; idxTrajet < trajets.length; idxTrajet++) {
              const trajet = trajets[idxTrajet]
              trajet.compteurs = [0, 0, 0, 0, 0]
              trajet.nbTrainsReels = 0
              trajet.nbWagons = 0
              trajet.nbWagonsMoyens = 0
              trajet.nb = 0
              trajet.ratio = 0
              trajet.selectionne = true
          }
          this.trajets = trajets
          this.moisEnCours()
        }.bind(this))
    },

    lire() {
      this.mettreAJourDates()
      const dateDebut = this.moment(this.dateDebut).format("YYYY-MM-DD")
      const dateFin = this.moment(this.dateFin).format("YYYY-MM-DD")
      var filtreTrajets = "";
      this.trajetsSelectionnes = this.trajets.filter(t => t.selectionne)
      this.trajetsSelectionnes.forEach(t => {
        if (filtreTrajets != "") {
          filtreTrajets += ";"
        }
        filtreTrajets += t.nom
      })
      const filter = "dateDebut=" + dateDebut + "&dateFin=" + dateFin + "&trajets=" + filtreTrajets
      var url = "/api/frFeuilleRoutes/nbWagonsParFlux?access_token=" + this.$user.id + "&" + filter;
      this.loading = true;
      this.$axios.get(url)
        .then(function (response) {
          this.listeFlux = response.data;
          setTimeout(function() {
            this.trains = response.data
            this.agregerResultatParGroupe(this.trains)
          }.bind(this), 50)
          this.loading = false;
        }.bind(this))
        .catch(function(error) {
          this.loading = false;
          this.afficherErreur(error.message + " " + (error.response != undefined ? error.response.statusText : ''))
        }.bind(this))
    },

    getObjectifDate(trajet, date) {
      if ((trajet == undefined) || (date == undefined)) {
        return 0
      }
      const d = new Date(date)
      if ((trajet.objectifsWagons == undefined) || (d == undefined)) {
        return 0
      }
      var objectif = trajet.objectifsWagons.find(o => date >= o.date)
      if (objectif == undefined) {
        return 0
      }
      return objectif.objectif

    },

    genererGroupesMois(trains)
    {
      moment.locale("fr")
      trains.sort((a, b) =>  a.frDepartDateTheorique < b.frDepartDateTheorique ? -1 : (a.frDepartDateTheorique > b.frDepartDateTheorique ? 1 : 0))
      const groupes = []
      trains.forEach(train => {
        const anneeMois = train.frDepartDateTheorique.substr(0, 7)
        const groupe = groupes.find(g => g.anneeMois == anneeMois)
        if (groupe == undefined) {
          groupes.push({
            nom: moment(train.frDepartDateTheorique).format("MMMM YYYY"),
            anneeMois: anneeMois
          })
        }
      })
      return groupes
    },

    agregerResultatParGroupe(trains) {
      const parTrajet = this.regroupement == this.REGROUPEMENT_TRAJET
      // Préparation des totaux de tous les groupes
      var totaux = {
        nbTrainsReels: 0,
        compteurs: [0, 0, 0, 0, 0],
        nbWagons: 0,
        nbWagonsMoyens: 0,
        nbWagonsObjectifs: 0,
        nb: 0,
        ratio: 0
      }

      // Création des groupes
      const groupes = parTrajet ? this.trajetsSelectionnes : this.genererGroupesMois(trains)
      // Itération groupe par groupe
      for (var idxGroupe = 0; idxGroupe < groupes.length; idxGroupe++) {
        const groupe = groupes[idxGroupe]
        // Initialisation des totaux du groupe
        groupe.nbTrainsReels = 0
        groupe.compteurs = [0, 0, 0, 0, 0]
        groupe.nbWagons = 0
        groupe.nbWagonsMoyens = 0
        groupe.nbWagonsObjectifs = 0
        groupe.nb = 0
        groupe.ratio = 0
        
        // Liste des trains correspondant au groupe
        const trainsGroupe = parTrajet ? trains.filter(t => (t.frDepartLieu + "-" + t.frArriveeLieu) == groupe.nom) : trains.filter(t => t.frDepartDateTheorique.substr(0, 7) == groupe.anneeMois)
        groupe.nb = trainsGroupe.length
        trainsGroupe.forEach(train => {
          groupe.nbTrainsReels += 1
          const nbWagonsTrain = ((train.frStatut == this.$FLUX_STATUT_OUVERT) || (train.frStatut == this.$FLUX_STATUT_FERME)) ? train["count(wgId)"] : 0
          groupe.nbWagons += nbWagonsTrain // Nb réel de wagons dans le train (sauf si annulé)
          const trajet = parTrajet ? groupe : this.trajetsSelectionnes.find(t2 => t2.nom == (train.frDepartLieu + "-" + train.frArriveeLieu))
          groupe.nbWagonsObjectifs += this.getObjectifDate(trajet, train.frDepartDateTheorique)
        });
        groupe.ratio = groupe.nbWagonsObjectifs > 0 ? 100 * groupe.nbWagons / groupe.nbWagonsObjectifs : 0
        groupe.nbWagonsMoyens = groupe.nbTrainsReels > 0 ? groupe.nbWagons / groupe.nbTrainsReels : 0
        for(var statut = 0; statut <= 4; statut++ ) {
          const nb = trainsGroupe.filter(t => t.frStatut == statut).length
          groupe.compteurs[statut] += nb
          totaux.compteurs[statut] += nb
        }

        totaux.nbTrainsReels += groupe.nbTrainsReels
        totaux.nbWagons += groupe.nbWagons
        //totaux.nbWagonsMoyens += groupe.nbWagonsMoyens
        totaux.nbTrainsReenbWagonsObjectifsls += groupe.nbWagonsObjectifs
        totaux.nb += groupe.nb
      }
      totaux.nbWagonsMoyens = totaux.nbTrainsReels > 0 ? totaux.nbWagons / totaux.nbTrainsReels : 0

      this.totaux = totaux
      const annulations = {labels: [], nb: [], pct: []}
      const nbAnnulations = trains.filter(t => t.frStatut == 4).length
      const annulationsParCategorie = this.annulationsParCategorie
      trains.filter(t => t.frStatut == 4).forEach(d => {
        var motif = d.frMotifAnnulation
        if ((annulationsParCategorie) && (d.frMotifAnnulation.indexOf("-") > 0))
          motif = d.frMotifAnnulation.split("-")[0].trim()
        console.log(d.frMotifAnnulation, "**", motif)
        var idx = annulations.labels.indexOf(motif)
        if (idx == -1) {
          const c0 = annulations.labels.find(l => l.toUpperCase() > motif.toUpperCase())
          idx = annulations.labels.indexOf(c0) != -1 ? annulations.labels.indexOf(c0) : annulations.labels.length
          annulations.labels.splice(idx, 0, motif)
          annulations.nb.splice(idx, 0, 1)
          annulations.pct.splice(idx, 0, (Math.round(100 / nbAnnulations)))
        }
        else {
          annulations.nb[idx]++
          annulations.pct[idx] = Math.round(100 * annulations.nb[idx] / nbAnnulations)
        }
      });
      if (annulations.labels.length == 0) {
        annulations.labels.push('-')
        annulations.nb.push('0')
        annulations.pct.push('0')
      }
      //annulations.labels.sort(function(a, b) { })
      //annulations.nb.forEach(nb => annulatiobs a.pct = 100 * a.nb / annulations.length)
      this.annulations = annulations
      console.log(this.annulations)

      this.groupes = groupes

      // Générer les données pour les graphiques
      this.genererGraphiques1() 
      this.genererGraphiques2() 
    },

    agregerResultat(trains) {
      // Itération trajet / trajet
      
      var totaux = {
        nbTrainsReels: 0,
        compteurs: [0, 0, 0, 0, 0],
        nbWagons: 0,
        nbWagonsMoyens: 0,
        nbWagonsObjectifs: 0,
        nb: 0,
        ratio: 0
      }
      for (var idxTrajet = 0; idxTrajet < this.trajetsSelectionnes.length; idxTrajet++) {
        const trajet = this.trajetsSelectionnes[idxTrajet]
        trajet.nbTrainsReels = 0
        trajet.compteurs = [0, 0, 0, 0, 0]
        trajet.nbWagons = 0
        trajet.nbWagonsMoyens = 0
        trajet.nbWagonsObjectifs = 0
        trajet.nb = 0
        trajet.ratio = 0
        
        // Liste des trains du trajet sur la période demandée
        const trainsTrajet = trains.filter(t => ((t.frDepartLieu + "-" + t.frArriveeLieu) == trajet.nom))
        trajet.nb = trainsTrajet.length
        trainsTrajet.forEach(t => {
          trajet.nbTrainsReels += 1
          const nbWagonsTrain = ((t.frStatut == this.$FLUX_STATUT_OUVERT) || (t.frStatut == this.$FLUX_STATUT_FERME)) ? t["count(wgId)"] : 0
          trajet.nbWagons += nbWagonsTrain // Nb réel de wagons dans le train (sauf si annulé)
          trajet.nbWagonsObjectifs += this.getObjectifDate(trajet, t.frDepartDateTheorique)
        });
        trajet.ratio = trajet.nbWagonsObjectifs > 0 ? 100 * trajet.nbWagons / trajet.nbWagonsObjectifs : 0
        trajet.nbWagonsMoyens = trajet.nbTrainsReels > 0 ? trajet.nbWagons / trajet.nbTrainsReels : 0
        for(var statut = 0; statut <= 4; statut++ ) {
          const nb = trainsTrajet.filter(t => t.frStatut == statut).length
          trajet.compteurs[statut] += nb
          totaux.compteurs[statut] += nb
        }

        totaux.nbTrainsReels += trajet.nbTrainsReels
        totaux.nbWagons += trajet.nbWagons
        //totaux.nbWagonsMoyens += trajet.nbWagonsMoyens
        totaux.nbTrainsReenbWagonsObjectifsls += trajet.nbWagonsObjectifs
        totaux.nb += trajet.nb
      }
      totaux.nbWagonsMoyens = totaux.nbTrainsReels > 0 ? totaux.nbWagons / totaux.nbTrainsReels : 0

      this.totaux = totaux
      const annulations = {labels: [], nb: [], pct: []}
      const nbAnnulations = trains.filter(t => t.frStatut == 4).length
      const annulationsParCategorie = this.annulationsParCategorie
      trains.filter(t => t.frStatut == 4).forEach(d => {
        var motif = d.frMotifAnnulation
        if ((annulationsParCategorie) && (d.frMotifAnnulation.indexOf("-") > 0))
          motif = d.frMotifAnnulation.split("-")[0].trim()
        console.log(d.frMotifAnnulation, "**", motif)
        var idx = annulations.labels.indexOf(motif)
        if (idx == -1) {
          const c0 = annulations.labels.find(l => l.toUpperCase() > motif.toUpperCase())
          idx = annulations.labels.indexOf(c0) != -1 ? annulations.labels.indexOf(c0) : annulations.labels.length
          annulations.labels.splice(idx, 0, motif)
          annulations.nb.splice(idx, 0, 1)
          annulations.pct.splice(idx, 0, (Math.round(100 / nbAnnulations)))
        }
        else {
          annulations.nb[idx]++
          annulations.pct[idx] = Math.round(100 * annulations.nb[idx] / nbAnnulations)
        }
      });
      if (annulations.labels.length == 0) {
        annulations.labels.push('-')
        annulations.nb.push('0')
        annulations.pct.push('0')
      }
      //annulations.labels.sort(function(a, b) { })
      //annulations.nb.forEach(nb => annulatiobs a.pct = 100 * a.nb / annulations.length)
      this.annulations = annulations
      console.log(this.annulations)

      // Générer les données pour les graphiques
      //this.genererGraphiques1() 
      //this.genererGraphiques2() 
    },

    genererGraphiques1()
    {
      const labels = []
      const data1 = []
      const data2 = []
       for (var idxGroupe = 0; idxGroupe < this.groupes.length; idxGroupe++) {
        const groupe = this.groupes[idxGroupe]
        labels.push(groupe.nom)
        data1.push(groupe.nbWagonsMoyens)
        data2.push(this.getObjectif(groupe))
      }
      if (this.chart1 == null) {
          this.chart1 = new Chart(this.$refs.chart1, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Objectif',
                data: data2,
                type: 'line',
                borderWidth: 1,
                borderColor: '#FF8000',
                backgroundColor: '#FFFFFF',
                fill: false
              },
              {
                type:'bar',
                label: 'Nb wagons moyen',
                data: data1,
                borderWidth: 1,
                backgroundColor: '#9BD0F5'
              },
            ]
          },
          options: {
            scales: {
              yAxes: [
              {
                id: 'nb',
                type: 'linear',
                position: 'left',
                ticks: {beginAtZero: true}
              }
            ]}
          }
        });
      }
      else {
        this.chart1.data.labels = labels
        this.chart1.data.datasets[0].data = data2
        this.chart1.data.datasets[1].data = data1
        this.chart1.update();
      }
    },

    genererGraphiques2() {
      if (this.chart2 == undefined) {
        this.chart2 = new Chart(this.$refs.chart2, {
          type: 'bar',
          data: {
            labels: this.annulations.labels,
            datasets: [
              {
                label: 'Nb annulations',
                data: this.annulations.nb,
                type: 'bar',
                borderWidth: 1,
                borderColor: 'red',
                backgroundColor: 'red',
                fill: false,
                yAxisID: 'nb',
                order: 1
              },
              {
                label: '% du total',
                data: this.annulations.pct,
                type: 'line',
                borderWidth: 1,
                borderColor: 'orange',
                backgroundColor: 'orange',
                fill: false,
                yAxisID: 'pct',
                order: 0
              },
            ]
          },
          options: {
            scales: {
              yAxes: [
              {
                id: 'nb',
                type: 'linear',
                position: 'left',
                ticks: {beginAtZero: true}
              },
              {
                id: 'pct',
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                  min: 0,
                  max: 100,
                  beginAtZero: true
                },
              }
            ]},
          }
        });      
      }
      else {
        this.chart2.data.labels = this.annulations.labels
        this.chart2.data.datasets[0].data = this.annulations.nb
        this.chart2.data.datasets[1].data = this.annulations.pct
        this.chart2.update()
      }
    },

    mettreAJourDates() {
      this.s1dateDebut = moment(this.dateDebut).format("YYYY-MM-DD")
      this.s1dateFin = moment(this.dateFin).format("YYYY-MM-DD")
    },

    moisEnCours() {
      const now = new Date()
      const d0 = 1
      const m0 = now.getMonth()
      const y0 = now.getFullYear()
      const d1 = 1
      const m1 = m0 == 11 ? 0 : m0 + 1
      const y1 = m0 == 11 ? y0 + 1 : y0
      this.dateDebut = new Date(y0, m0, d0)
      this.dateFin = new Date(y1, m1, d1)
      this.lire()
    },


    moisMoinsUn() {
      this.dateDebut = this.moment(this.dateDebut).subtract(1, 'months')
      this.dateFin = this.moment(this.dateFin).subtract(1, 'months')
      this.lire()
    },

    moisPlusUn() {
      this.dateDebut = this.moment(this.dateDebut).add(1, 'months')
      this.dateFin = this.moment(this.dateFin).add(1, 'months')
      this.lire()
    },

    anneeEnCours() {
      const now = new Date()
      const d0 = 1
      const m0 = 0
      const y0 = now.getFullYear()
      const d1 = 1
      const m1 = 0
      const y1 = y0 + 1
      this.dateDebut = new Date(y0, m0, d0)
      this.dateFin = new Date(y1, m1, d1)
      this.lire()
    },

    anneePlusUn() {
      this.dateDebut = this.moment(this.dateDebut).add(1, 'years')
      this.dateFin = this.moment(this.dateFin).add(1, 'years')
      this.lire()
    },

    anneeMoinsUn() {
      this.dateDebut = this.moment(this.dateDebut).subtract(1, 'years')
      this.dateFin = this.moment(this.dateFin).subtract(1, 'years')
      this.lire()
    },

    saisieDateFinChangeEvent() {
      this.dateDebut = new Date(this.s1dateDebut)
      this.dateFin = new Date(this.s1dateFin)
      this.lire()
    },

    /**
     * Couleur du cadre de la feuille de route = f(statut)
     * @param {Number} frStatut 
     */
     getStatutColor(frStatut) {
      //console.log(fr)
      switch(frStatut) {
        case 0: return "white"              // Prévisionnel
        case 1: return 'blue lighten-3'     // Couvert
        case 2: return 'yellow lighten-3'   // Ouvert
        case 3: return 'green lighten-3'    // Fermé ok
        case 4: return 'red'                // Annulé
      }
      return 'orange'
    },

    /**
     * Couleur du cadre de la feuille de route = f(statut)
     * @param {Number} frStatut 
     */
     getStatutText(frStatut) {
      //console.log(fr)
      switch(frStatut) {
        case 0: return "Prévisionnel"              // Prévisionnel
        case 1: return 'Couvert'     // Couvert
        case 2: return 'Ouvert'   // Ouvert
        case 3: return 'Fermé'    // Fermé ok
        case 4: return 'Annulé'                // Annulé
      }
      return '-'
    },

    boutonOuvrirPopupModifierTrajets() {
      this.$refs.popupModifierTrajets.open(this.trajets)
    },

    trajetsValidesEventHandler(trajets) {
      console.log(trajets)
      this.trajets = trajets
      const url = "/api/frFeuilleRoutes/trajetsKpi?access_token=" + this.$user.id
      this.$axios.post(url, this.trajets)
      this.lire()
    },

    getObjectif(trajet) {
      if (trajet == undefined) {
        return ""
      }
      if (trajet.objectifsWagons == undefined) {
        return ""
      }
      if ((trajet.objectifsWagons.length == undefined) || (trajet.objectifsWagons.length == 0)) {
        return ""
      }

      const now = new Date()
      var idx = 0 
      var objectif = 0
      while (idx < trajet.objectifsWagons.length) {
        const d = new Date(trajet.objectifsWagons[idx].date)
        if (now >= d) {
          objectif = trajet.objectifsWagons[idx].objectif
        }
        idx++
      }
      return objectif
    },

    trajetsSelectionnesToString(trajetsSelectionnes)
    {
      var trajets = ""
      trajetsSelectionnes.forEach(trajet => {
        if (trajets != "") {
          trajets += " "
        }
        trajets += trajet.nom
      });
      return trajets
    },

    trierTrajets(cle, asc)
    {
      if (asc) {
        this.listeFlux.sort((a, b) => a[cle] < b[cle] ? -1 : (a[cle] > b[cle] ? 1 : 0))
      }
      else {
        this.listeFlux.sort((a, b) => a[cle] < b[cle] ? 1 : (a[cle] > b[cle] ? -1 : 0))
      }
    },

    boutonRegrouperParCategorieClick()
    {
      //this.lire();
      this.agregerResultatParGroupe(this.trains)
    }

  }
}
</script>

<style scoped>

.indice {
  padding-top: 4px; 
  font-size: 16px;
  font-style: italic;
  color: #404040;
  font-weight: normal;
}

table, th, td {
  border: 1px solid black !important;
  margin: 10px;
  padding: 5px;
  border-collapse: collapse;
}

th {
  background-color: cadetblue !important;
  color: white !important;
  text-align: center !important;
}

.couvert {
  background-color: #90CAF9;
}

.ouvert {
  background-color: #FFF59D;
}

.fermeok {
  background-color: #A5D6A7;
}

.annule {
  background-color: red;
  color: white;
}
</style>